.nonmodal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10; /* Sit on top */
    padding-top: 10px; /*100px; /* Location of the box */
    left: calc(50% - 100px); /* 60px */
    top: 20px;
    width:auto; /*: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
   /* background-color: rgb(0,0,0); /* Fallback color */
   /* background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.nonmodal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 16px;
    border: 1px solid #888;
    width: 100%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Header */
.nonmodal-header {
    padding: 2px 2px 2px 16px;
    /* background-color: lightblue; /* #fefefe; /* #5cb85c; */
    background-color: #007858;   
    color: white;
    /* color: white; */
}

/* Modal Body */
.nonmodal-body {
  padding: 2px 16px;
  font: 12px Tahoma;  
}

/* Modal Footer */
.nonmodal-footer {
  padding: 2px 16px;
  background-color: #fefefe; /* #5cb85c; */
  color: white;
}
